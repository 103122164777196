.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.data {
  width: 15rem;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.title-header {
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  font-family: Lato, Geneva, Tahoma, sans-serif;
}

.presidentsTitle {
  text-align: center;
  color: #C8102E;
  border: red;
  border-radius: 4px;
  font-family: 'Cormorant', serif;
}

.universityName {
  text-align: left;
  color: #ffffff;
  font-size: 20px;
  font-family: Lato, Geneva, Tahoma, sans-serif;
  margin-top: 0.4em;
  margin-bottom: 0.4em;
  /* padding-left: pt; */
}

.welcome {
  text-align: left;
  color: #ffffff;
  font-size: 28px;
  font-family: Lato, Geneva, Tahoma, sans-serif;
  font-family: 'Cormorant', serif;
  margin-top: 0.1em;
  margin-left: 1em;
  margin-bottom: 0.5em;
  /* padding-left: pt; */
}

.ceoName {
  text-align: left;
  color: #ffffff;
  font-size: 28px;
  font-family: Lato, Geneva, Tahoma, sans-serif;
  font-family: 'Cormorant', serif; 
  margin-top: 0.5em;
  margin-left: 1em;
  margin-bottom: 0.2em;
  /* padding-left: pt; */
}

.presidentName {
  text-align: left;
  color: #ffffff;
  font-size: 20px;
  font-family: 'Lato', sans-serif;
  padding-left: 50pt;
}

.text {
  width: 247px;
}

.name1 {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #E5621C;
  font-size: 20px;
  padding-left: 50pt;
}

.presNames {
  margin-right: 0;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  padding: 10pt;
  background-color: #282c34;
}

::placeholder {
  color: rgb(226, 216, 216)
}

.inputs {
  background-color: #A4804A;
  border: none;
  color: white;
  
  padding: 15px 32px;
  margin-right: 5pt;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  border-radius: 4px;
  /* border-style: solid; */
  /* border-width: 2pt; */
}

.button {
  background-color: #A4804A;
  border: none;
  color: white;
  padding: 15px 32px;
  margin-left: 5pt;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  border-radius: 4px;
}

.button2 {
  margin: 0;
  padding: 0;
  overflow: hidden;
  border: hidden;
}


.button:hover {
  background-color: #a4804aa1;
  transition: 0.3s;
}

.buttonModifiers {
  border-radius: 10px;
  background-color: rgba(241, 229, 211, 0.692);
  cursor: pointer;
}

.buttonModifiers:hover {
  background-color: rgb(236, 216, 161);
  transition: 0.3s;
  transition-duration: 0.4s;
  overflow: hidden;
  position: relative;

}

.buttonModifiers:active {
  background-color: #dda407;
  box-shadow: 1px #666;
  transform: translateY(2px);
}

.component-relations {
  background-image: url("assets/b.svg");
  background-repeat: no-repeat;
  background-size: cover;
  /* background-image: linear-gradient(to top, #BB4100,#f1c0a3); */
  display: flex;
  flex-direction: row;
  height: 100vh;
  /* border-radius: 25px;
  border-style: solid;
  border-color: #69d0ec; */
}

.info {
  display: flex;
  flex-direction: row;
}

ol.orderedList {
  list-style-type: none;
  column-count: 3;
  column-gap: 0px;
  padding-left: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

p {
  padding: 0.5rem;
  padding-left: 1rem;
  font-family: 'Lato', sans-serif;
}

form {
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

}

img {
  scale: 80%;
}